:root{
  --light-primary-main: #1b76d4;
  --light-primary-main-hover: #0e7cea;
  --light-text-main: #000000;
  --light-text-secondary: #656565;
  --light-text-disabled: #949494;
  --light-error-main: #ff5252;
  --dark-primary-main: #64b5f5;
  --dark-text-main: #ffffff;
  --dark-text-secondary: #cccccc;
  --dark-text-disabled: rgba(255, 255, 255, 0.35);
  --dark-error-main: #424141;
  --app-padding-side: 50px;
  --app-padding-header: 60px;
  --app-padding-footer: 80px;
  --app-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif
}

@media (max-width:600px) {
  :root {
    --app-padding-side: 20px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,html {
  height: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
